"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PureContextNode = void 0;
const event_emitter_1 = require("../../event-emitter");
class PureContextNode {
    id = null;
    contextType;
    contextLevel;
    namespace;
    parentNode = null;
    children = [];
    insPoints = []; // ToDo: replace with Map
    element = null;
    #parsedContext = {};
    #isVisible = false;
    #eventEmitter = new event_emitter_1.EventEmitter(); // ToDo: implement event bubbling?
    get parsedContext() {
        return this.#parsedContext;
    }
    set parsedContext(parsedContext) {
        this.#parsedContext = parsedContext;
        this.#eventEmitter.emit('contextChanged', {});
    }
    get isVisible() {
        return this.#isVisible;
    }
    set isVisible(value) {
        this.#isVisible = value;
        this.#eventEmitter.emit('visibilityChanged', {});
    }
    constructor(namespace, contextType, parsedContext = {}, insPoints = [], element = null, contextLevel) {
        this.namespace = namespace;
        this.contextType = contextType;
        this.parsedContext = parsedContext;
        this.insPoints = insPoints;
        this.element = element;
        this.contextLevel = contextLevel;
        // ToDo: the similar logic is in tree builder
        this.id = parsedContext.id ?? null;
    }
    removeChild(child) {
        child.parentNode = null;
        this.children = this.children.filter((c) => c !== child);
        this.#eventEmitter.emit('childContextRemoved', { child });
        // ToDo: remove children of removed context?
    }
    appendChild(child) {
        child.parentNode = this;
        this.children.push(child);
        this.#eventEmitter.emit('childContextAdded', { child });
    }
    appendInsPoint(insertionPoint) {
        this.insPoints.push(insertionPoint);
        this.#eventEmitter.emit('insertionPointAdded', { insertionPoint });
    }
    removeInsPoint(insertionPointName) {
        const insPointToRemove = this.insPoints.find((ip) => ip.name === insertionPointName);
        if (!insPointToRemove)
            return;
        this.insPoints = this.insPoints.filter((ip) => ip.name !== insertionPointName);
        this.#eventEmitter.emit('insertionPointRemoved', { insertionPoint: insPointToRemove });
    }
    on(eventName, callback) {
        return this.#eventEmitter.on(eventName, callback);
    }
    toTransferable(opt) {
        return {
            namespace: this.namespace,
            contextType: this.contextType,
            id: this.id,
            parsedContext: { ...this.parsedContext },
            parentNode: opt.dir === 'up' && this.parentNode ? this.parentNode.toTransferable(opt) : null,
            children: opt.dir === 'down' ? this.children.map((ch) => ch.toTransferable(opt)) : undefined,
            contextLevel: this.contextLevel,
        };
    }
    static fromTransferable(transferable) {
        return new PureContextNode(transferable.namespace, transferable.contextType, { ...transferable.parsedContext }, [], // insertion points are not transferable
        null, // element is not transferable
        transferable.contextLevel);
    }
}
exports.PureContextNode = PureContextNode;
