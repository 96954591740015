"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsSerivce = void 0;
const local_db_service_1 = require("../local-db/local-db.service");
// Local DB
const FAVORITE_MUTATION = 'favorite-mutation';
const PREFERRED_SOURCE = 'preferred-source';
const MUTATION_LAST_USAGE = 'mutation-last-usage';
const STOPPED_APPS = 'stopped-apps';
const MUTATION_VERSION = 'mutation-version';
const SELECTED_MUTATION = 'selected-mutation';
class SettingsSerivce {
    eventService;
    localDb;
    constructor(eventService, localDb) {
        this.eventService = eventService;
        this.localDb = localDb;
    }
    async getFavoriteMutation(contextId) {
        const key = local_db_service_1.LocalDbService.makeKey(FAVORITE_MUTATION, contextId);
        return this.localDb.getItem(key);
    }
    async setFavoriteMutation(contextId, mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(FAVORITE_MUTATION, contextId);
        await this.localDb.setItem(key, mutationId);
        this.eventService.emit('favoriteMutationChanged', { mutationId, contextId });
    }
    async getSelectedMutation(contextId) {
        const key = local_db_service_1.LocalDbService.makeKey(SELECTED_MUTATION, contextId);
        return this.localDb.getItem(key);
    }
    async setSelectedMutation(contextId, mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(SELECTED_MUTATION, contextId);
        await this.localDb.setItem(key, mutationId);
        this.eventService.emit('selectedMutationChanged', { mutationId, contextId });
    }
    async getPreferredSource(mutationId, contextId) {
        const key = local_db_service_1.LocalDbService.makeKey(PREFERRED_SOURCE, mutationId, contextId);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setPreferredSource(mutationId, contextId, source) {
        const key = local_db_service_1.LocalDbService.makeKey(PREFERRED_SOURCE, mutationId, contextId);
        await this.localDb.setItem(key, source);
        this.eventService.emit('preferredSourceChanged', { mutationId, contextId, source });
    }
    async getMutationLastUsage(mutationId, contextId) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_LAST_USAGE, mutationId, contextId);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setMutationLastUsage(mutationId, contextId, value) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_LAST_USAGE, mutationId, contextId);
        await this.localDb.setItem(key, value);
        this.eventService.emit('mutationLastUsageChanged', { mutationId, contextId, value });
    }
    async getAppEnabledStatus(mutationId, appInstanceId) {
        const key = local_db_service_1.LocalDbService.makeKey(STOPPED_APPS, mutationId, appInstanceId);
        return (await this.localDb.getItem(key)) ?? true; // app is active by default
    }
    async setAppEnabledStatus(mutationId, appInstanceId, isEnabled) {
        const key = local_db_service_1.LocalDbService.makeKey(STOPPED_APPS, mutationId, appInstanceId);
        await this.localDb.setItem(key, isEnabled);
        this.eventService.emit('appEnabledStatusChanged', {
            mutationId,
            appInstanceId,
            isEnabled,
        });
    }
    async getMutationVersion(mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_VERSION, mutationId);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setMutationVersion(mutationId, version = null) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_VERSION, mutationId);
        await this.localDb.setItem(key, version);
        this.eventService.emit('mutationVersionChanged', { mutationId, version });
    }
}
exports.SettingsSerivce = SettingsSerivce;
