"use strict";
// import { Runtime } from 'webextension-polyfill'
// import ManifestDTO from '../background/dto/manifestDTO'
// import VersionInfo from '../background/models/versionInfo'
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedAccountsPairStatus = exports.ConnectedAccountsRequestStatus = exports.UrlAvailability = exports.LoginRequestFromOptions = exports.ReusePolicyOptions = exports.SecureLoginOptions = exports.SystemOverlayTabs = exports.OverlayStorages = exports.NearNetworks = exports.WalletTypes = exports.ChainTypes = exports.DefaultSigners = exports.Environments = void 0;
var Environments;
(function (Environments) {
    Environments["Dev"] = "dev";
    Environments["Test"] = "test";
    Environments["Main"] = "main";
})(Environments || (exports.Environments = Environments = {}));
var DefaultSigners;
(function (DefaultSigners) {
    DefaultSigners["EXTENSION"] = "extension";
})(DefaultSigners || (exports.DefaultSigners = DefaultSigners = {}));
var ChainTypes;
(function (ChainTypes) {
    ChainTypes["ETHEREUM_SEPOLIA"] = "ethereum/sepolia";
    ChainTypes["ETHEREUM_XDAI"] = "ethereum/xdai";
    ChainTypes["NEAR_TESTNET"] = "near/testnet";
    ChainTypes["NEAR_MAINNET"] = "near/mainnet";
})(ChainTypes || (exports.ChainTypes = ChainTypes = {}));
var WalletTypes;
(function (WalletTypes) {
    WalletTypes["WALLETCONNECT"] = "walletconnect";
    WalletTypes["METAMASK"] = "metamask";
    WalletTypes["NEAR"] = "near";
    WalletTypes["DAPPLETS"] = "dapplets";
})(WalletTypes || (exports.WalletTypes = WalletTypes = {}));
var NearNetworks;
(function (NearNetworks) {
    NearNetworks["Mainnet"] = "mainnet";
    NearNetworks["Testnet"] = "testnet";
})(NearNetworks || (exports.NearNetworks = NearNetworks = {}));
var OverlayStorages;
(function (OverlayStorages) {
    OverlayStorages["Centralized"] = "centralized";
    OverlayStorages["Decentralized"] = "decentralized";
})(OverlayStorages || (exports.OverlayStorages = OverlayStorages = {}));
var SystemOverlayTabs;
(function (SystemOverlayTabs) {
    SystemOverlayTabs["DAPPLET_CONFIRMATION"] = "DAPPLET_CONFIRMATION";
    SystemOverlayTabs["LOGIN_SESSION"] = "LOGIN_SESSION";
    SystemOverlayTabs["CONNECTED_ACCOUNTS"] = "CONNECTED_ACCOUNTS";
})(SystemOverlayTabs || (exports.SystemOverlayTabs = SystemOverlayTabs = {}));
var SecureLoginOptions;
(function (SecureLoginOptions) {
    SecureLoginOptions["Required"] = "required";
    SecureLoginOptions["Optional"] = "optional";
    SecureLoginOptions["Disabled"] = "disabled";
})(SecureLoginOptions || (exports.SecureLoginOptions = SecureLoginOptions = {}));
var ReusePolicyOptions;
(function (ReusePolicyOptions) {
    ReusePolicyOptions["Auto"] = "auto";
    ReusePolicyOptions["Manual"] = "manual";
    ReusePolicyOptions["Disabled"] = "disabled";
})(ReusePolicyOptions || (exports.ReusePolicyOptions = ReusePolicyOptions = {}));
var LoginRequestFromOptions;
(function (LoginRequestFromOptions) {
    LoginRequestFromOptions["Me"] = "me";
    LoginRequestFromOptions["Any"] = "any";
})(LoginRequestFromOptions || (exports.LoginRequestFromOptions = LoginRequestFromOptions = {}));
var UrlAvailability;
(function (UrlAvailability) {
    UrlAvailability["AVAILABLE"] = "AVAILABLE";
    UrlAvailability["NETWORK_ERROR"] = "NETWORK_ERROR";
    UrlAvailability["SERVER_ERROR"] = "SERVER_ERROR";
})(UrlAvailability || (exports.UrlAvailability = UrlAvailability = {}));
var ConnectedAccountsRequestStatus;
(function (ConnectedAccountsRequestStatus) {
    ConnectedAccountsRequestStatus["NotFound"] = "not found";
    ConnectedAccountsRequestStatus["Pending"] = "pending";
    ConnectedAccountsRequestStatus["Approved"] = "approved";
    ConnectedAccountsRequestStatus["Rejected"] = "rejected";
})(ConnectedAccountsRequestStatus || (exports.ConnectedAccountsRequestStatus = ConnectedAccountsRequestStatus = {}));
var ConnectedAccountsPairStatus;
(function (ConnectedAccountsPairStatus) {
    ConnectedAccountsPairStatus["Processing"] = "Processing";
    ConnectedAccountsPairStatus["Connected"] = "Connected";
    ConnectedAccountsPairStatus["Error"] = "Error";
})(ConnectedAccountsPairStatus || (exports.ConnectedAccountsPairStatus = ConnectedAccountsPairStatus = {}));
// export type ManifestAndDetails = ManifestDTO & {
//   isLoading: boolean
//   isActionLoading: boolean
//   isHomeLoading: boolean
//   error: string
//   versions: string[]
// }
// export type DappletLoadingResult = {
//   name: string
//   branch: string
//   version: string
//   runtime?: DappletRuntimeResult
//   error?: string
// }
// export type DappletRuntimeResult = {
//   isActionHandler: boolean
//   isHomeHandler: boolean
// }
// export type JsonValue =
//   | string
//   | number
//   | boolean
//   | null
//   | JsonValue[]
//   | { [key: string]: JsonValue }
// export type MessageWrapperRequest = {
//   request: {
//     handler: string
//     type: string
//     payload: {
//       path: string
//       args: JsonValue[]
//     }
//   }
//   sender: Runtime.MessageSender
// }
// export type ContentDetector = {
//   contextId: string
//   selector: string
// }
// export type SandboxEnvironmentVariables = {
//   preferedOverlayStorage: string
//   swarmGatewayUrl: string
// }
// export type SandboxInitializationParams = {
//   manifest: VersionInfo
//   defaultConfig: DefaultConfig
//   schemaConfig: SchemaConfig
//   env: SandboxEnvironmentVariables
// }
// export type ContextBuilder = {
//   [key: string]: ContextBuilder | string
// }
// export type ParserConfig = {
//   themes?: {
//     DARK?: string
//     LIGHT?: string
//   }
//   contexts: {
//     [contextName: string]: {
//       containerSelector: string
//       contextSelector?: string
//       widgets?: {
//         [widgetName: string]: {
//           styles?: string
//           insertionPoint: string
//           insert?: string
//         }
//       }
//       events?: {
//         [eventName: string]: {
//           element: string
//           listen: string
//           data?: {
//             [key: string]: string
//           }
//         }
//       }
//       contextBuilder: ContextBuilder
//       childrenContexts?: string[]
//     }
//   }
// }
// /**
//  * @description
//  * The base type for all module-level events used by the EventBus system.
//  * */
// export type BaseEvent = {
//   namespace: string
//   type: string
// }
