"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationService = void 0;
const target_service_1 = require("../target/target.service");
class ApplicationService {
    applicationRepository;
    settingsService;
    constructor(applicationRepository, settingsService) {
        this.applicationRepository = applicationRepository;
        this.settingsService = settingsService;
    }
    async getApplications() {
        // ToDo: out of gas
        const apps = await this.applicationRepository.getItems();
        return apps.map((app) => app.toDto());
    }
    async getApplication(appId) {
        const app = await this.applicationRepository.getItem({ id: appId });
        return app?.toDto() ?? null;
    }
    async getAppsFromMutation(mutation) {
        return Promise.all(mutation.apps.map((appInstance) => this.getAppInstanceWithSettings(mutation.id, appInstance))).then((apps) => apps.filter((app) => app !== null));
    }
    async createApplication(dto, tx) {
        const app = await this.applicationRepository.constructItem(dto);
        return this.applicationRepository.createItem(app, tx);
    }
    filterSuitableApps(appsToCheck, context) {
        const suitableApps = [];
        for (const app of appsToCheck) {
            const suitableTargets = app.targets.filter((target) => target_service_1.TargetService.isTargetMet(target, context));
            if (suitableTargets.length > 0) {
                // ToDo: this modifies the original array
                app.targets = suitableTargets;
                suitableApps.push(app);
            }
        }
        return suitableApps;
    }
    async enableAppInstanceInMutation(mutationId, appInstanceId) {
        await this.settingsService.setAppEnabledStatus(mutationId, appInstanceId, true);
    }
    async disableAppInstanceInMutation(mutationId, appInstanceId) {
        await this.settingsService.setAppEnabledStatus(mutationId, appInstanceId, false);
    }
    static constructAppInstanceId({ appId, documentId }) {
        // ToDo: instance id is a concatenation of app id and document id
        return documentId ? `${appId}/${documentId}` : appId;
    }
    async getAppInstanceWithSettings(mutationId, appInstance) {
        const instanceId = ApplicationService.constructAppInstanceId(appInstance);
        // ToDo: local or remote?
        const [app, settings] = await Promise.all([
            this.getApplication(appInstance.appId),
            this._getAppInstanceSettings(mutationId, instanceId),
        ]);
        if (!app)
            return null;
        const appWithSettings = app;
        // ToDo: this modifies the original object
        appWithSettings.settings = settings;
        appWithSettings.instanceId = instanceId;
        appWithSettings.documentId = appInstance.documentId;
        return app;
    }
    async _getAppInstanceSettings(mutationId, appInstanceId) {
        return {
            isEnabled: await this.settingsService.getAppEnabledStatus(mutationId, appInstanceId),
        };
    }
}
exports.ApplicationService = ApplicationService;
