"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Engine = void 0;
const config_1 = require("./config");
const near_signer_service_1 = require("./services/near-signer/near-signer.service");
const social_db_service_1 = require("./services/social-db/social-db.service");
const local_db_service_1 = require("./services/local-db/local-db.service");
const local_storage_1 = require("./services/local-db/local-storage");
const mutation_service_1 = require("./services/mutation/mutation.service");
const application_service_1 = require("./services/application/application.service");
const user_link_service_1 = require("./services/user-link/user-link.service");
const parser_config_service_1 = require("./services/parser-config/parser-config.service");
const link_db_service_1 = require("./services/link-db/link-db.service");
const document_service_1 = require("./services/document/document.service");
const unit_of_work_service_1 = require("./services/unit-of-work/unit-of-work.service");
const notification_service_1 = require("./services/notification/notification.service");
const settings_service_1 = require("./services/settings/settings.service");
const base_repository_1 = require("./services/base/base.repository");
const mutation_entity_1 = require("./services/mutation/mutation.entity");
const application_entity_1 = require("./services/application/application.entity");
const user_link_entity_1 = require("./services/user-link/user-link.entity");
const parser_config_entity_1 = require("./services/parser-config/parser-config.entity");
const document_entity_1 = require("./services/document/document.entity");
const link_db_entity_1 = require("./services/link-db/link-db.entity");
const notification_entity_1 = require("./services/notification/notification.entity");
const resolution_entity_1 = require("./services/notification/resolution.entity");
const base_local_repository_1 = require("./services/base/base-local.repository");
const base_agg_repository_1 = require("./services/base/base-agg.repository");
const connected_accounts_service_1 = require("./services/connected-accounts/connected-accounts.service");
const event_service_1 = require("./services/event/event.service");
class Engine {
    config;
    #selector;
    eventService;
    linkDbService;
    mutationService;
    applicationService;
    userLinkService;
    parserConfigService;
    notificationService;
    documentService;
    connectedAccountsService;
    constructor(config) {
        this.config = config;
        if (!this.config.storage) {
            this.config.storage = new local_storage_1.LocalStorage(`mweb:${config.networkId}`);
        }
        const storage = this.config.storage;
        this.#selector = this.config.selector;
        const nearConfig = (0, config_1.getNearConfig)(this.config.networkId);
        this.eventService = new event_service_1.EventService(this.config.eventEmitter);
        const localDb = new local_db_service_1.LocalDbService(storage);
        const nearSigner = new near_signer_service_1.NearSigner(this.#selector, localDb, nearConfig);
        const socialDb = new social_db_service_1.SocialDbService(nearSigner, nearConfig.contractName);
        const settingsService = new settings_service_1.SettingsSerivce(this.eventService, localDb);
        const unitOfWorkService = new unit_of_work_service_1.UnitOfWorkService(socialDb);
        const mutationRemoteRepository = new base_repository_1.BaseRepository(mutation_entity_1.Mutation, socialDb);
        const applicationRemoteRepository = new base_repository_1.BaseRepository(application_entity_1.AppMetadata, socialDb);
        const userLinkRemoteRepository = new base_repository_1.BaseRepository(user_link_entity_1.IndexedLink, socialDb);
        const parserConfigRemoteRepository = new base_repository_1.BaseRepository(parser_config_entity_1.ParserConfig, socialDb);
        const documentRemoteRepository = new base_repository_1.BaseRepository(document_entity_1.Document, socialDb);
        const linkDbRemoteRepository = new base_repository_1.BaseRepository(link_db_entity_1.CtxLink, socialDb);
        const notificationRemoteRepository = new base_repository_1.BaseRepository(notification_entity_1.Notification, socialDb);
        const resolutionRemoteRepository = new base_repository_1.BaseRepository(resolution_entity_1.Resolution, socialDb);
        const mutationLocalRepository = new base_local_repository_1.BaseLocalRepository(mutation_entity_1.Mutation, storage);
        const applicationLocalRepository = new base_local_repository_1.BaseLocalRepository(application_entity_1.AppMetadata, storage);
        const userLinkLocalRepository = new base_local_repository_1.BaseLocalRepository(user_link_entity_1.IndexedLink, storage);
        const parserConfigLocalRepository = new base_local_repository_1.BaseLocalRepository(parser_config_entity_1.ParserConfig, storage);
        const documentLocalRepository = new base_local_repository_1.BaseLocalRepository(document_entity_1.Document, storage);
        const linkDbLocalRepository = new base_local_repository_1.BaseLocalRepository(link_db_entity_1.CtxLink, storage);
        const notificationLocalRepository = new base_local_repository_1.BaseLocalRepository(notification_entity_1.Notification, storage);
        const resolutionLocalRepository = new base_local_repository_1.BaseLocalRepository(resolution_entity_1.Resolution, storage);
        const mutationRepository = new base_agg_repository_1.BaseAggRepository(mutationRemoteRepository, mutationLocalRepository);
        const applicationRepository = new base_agg_repository_1.BaseAggRepository(applicationRemoteRepository, applicationLocalRepository);
        const userLinkRepository = new base_agg_repository_1.BaseAggRepository(userLinkRemoteRepository, userLinkLocalRepository);
        const parserConfigRepository = new base_agg_repository_1.BaseAggRepository(parserConfigRemoteRepository, parserConfigLocalRepository);
        const documentRepository = new base_agg_repository_1.BaseAggRepository(documentRemoteRepository, documentLocalRepository);
        const linkDbRepository = new base_agg_repository_1.BaseAggRepository(linkDbRemoteRepository, linkDbLocalRepository);
        const notificationRepository = new base_agg_repository_1.BaseAggRepository(notificationRemoteRepository, notificationLocalRepository);
        const resolutionRepository = new base_agg_repository_1.BaseAggRepository(resolutionRemoteRepository, resolutionLocalRepository);
        this.linkDbService = new link_db_service_1.LinkDbService(linkDbRepository);
        this.notificationService = new notification_service_1.NotificationService(notificationRepository, resolutionRepository, unitOfWorkService, nearSigner);
        this.mutationService = new mutation_service_1.MutationService(mutationRepository, settingsService, this.notificationService, this.eventService, unitOfWorkService, nearConfig, nearSigner);
        this.applicationService = new application_service_1.ApplicationService(applicationRepository, settingsService);
        this.userLinkService = new user_link_service_1.UserLinkService(userLinkRepository, this.applicationService, nearSigner);
        this.parserConfigService = new parser_config_service_1.ParserConfigService(parserConfigRepository);
        this.documentService = new document_service_1.DocumentSerivce(documentRepository, this.mutationService, nearSigner);
        this.connectedAccountsService = new connected_accounts_service_1.ConnectedAccountsService(nearSigner, nearConfig.connectedAccountsContractAddress);
    }
}
exports.Engine = Engine;
